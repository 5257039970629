@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-Thin.ttf') format('truetype');
  font-weight: 100;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea,
input {
  margin: 0;
  outline: none;
}

select {
  word-wrap: normal;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-appearance,
input::-webkit-search-results-decoration {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='file'] {
  display: none;
  width: 350rem;
  max-width: 100%;
  color: #444;
  padding: 5rem;
  background: #fff;
  border-radius: 10rem;
  border: 1rem solid #555;
}

input[type='file']::file-selector-button {
  margin-right: 20rem;
  border: none;
  color: #1a7fbf;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.signature {
  display: block;
  border: 1px solid #82868c;
  border-radius: 10rem;
  width: 500rem;
  height: 200rem;
  background-color: var(--color-canvas);

  @media screen and (max-width: 500px) {
    max-width: 100%;
    width: 100%;
  }
}

.sign-quotation .signature {
  width: 650rem;

  @media screen and (max-width: 500px) {
      max-width: 100%;
      width: 100%;
    }
}

.report-signature {
  display: block;
  border: 1px solid #82868c;
  border-radius: 10rem;
  width: 350rem;
  height: 250rem;
  background-color: var(--color-canvas);

  @media screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
  }
}

.signature-red-border {
  border: 1rem solid #eb4444;
}
